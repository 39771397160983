* {
  box-sizing: border-box;
  font-family: 'Comic sans mf', cursive, sans-serif;
  background-color: transparent;
}

@media screen and (max-width: 430px) {
  * {
    font-family: sans-serif !important;
  }
}

.navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 50px;
}

.display{
  width: 100%;
  height: 150px;
  position: relative;
  z-index: 1;
} 

.navbarResponsive{
  display: none;
}

@media screen and (max-width: 1000px) {
  .navbarResponsive{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px;
    z-index: 5;
  }
  .navbar{
    display: none;
  }
}

.showMenuClose{
  display: none;
}

.navbarResponsiveColumn{
  position: fixed;
  z-index: 6;
  margin-top: 330px;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  width: 200px;
  height: 200px;
  padding: 10px;
  background-color: white;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.navbar-itemsResponsive{
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bold;
  border: none;
  color: black;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-itemsResponsive:hover{
  color: #585858;
  }

.menuResponsive{
  font-size: 20px;
  margin-bottom: 5px;
  margin-left: 30px;
  font-weight: bold;
  border: none;
  color: black;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

.navbar-items{
  font-size: 20px;
  font-weight: bold;
  border: none;
  color: black;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-items:hover{
color: #585858;
}

.divForLogo{
  position: relative;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jungle-sign{
  z-index: 3;
  position: absolute;
  width: 150px;
  height: 50px;
  margin-bottom: 20px;
}


.horizontalBlock{
display: flex;
flex-direction: row;
justify-content: center;

}

.noLink{
  cursor: not-allowed;
}

.dotsBlock{
display: flex;
justify-content: center;
} 

.repeatBlock{
display: flex;
flex-direction: column;
justify-content: space-between;
}

.verticalBlocks{
display: flex;
flex-direction: row;
}

.verticalBlocksLast{ 
display: flex;
flex-direction: row;
margin-bottom: 70px;
}

.verticalBlocksResponsiveRepeatBlock{
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  margin-top: 50px;
  }

  .verticalBlocksLastResponsiveRepeatBlock{
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: unset;
    margin-top: 50px;
    }

  .repeatBlockHorizontal{
    display: flex;
    justify-content: center;
  }

  .repeatBlockResponsive {
    display: none;
  }

  @media screen and (max-width: 1000px) {
    .repeatBlockHorizontal {
      display: none;
    }
    .repeatBlockResponsive {
      display: flex;
    }
    .hskDot{
      margin-left: -205px;
    }
    .noLink{
      margin-left: -205px;
    }
  }

.dots{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -25px;
  background-color: transparent;
  padding: 0;
  margin-left: 250px;
  align-self: center;
}

.dot {
  height: 350px;
  width: 350px;
  padding-top: 150px;
  font-size: 35px;
  color: rgb(1, 20, 8);
  font-weight: bold;
  text-align: center;
  background-color: rgb(200, 224, 93);
  border-radius: 50%;
  display: inline-block;
}

.dotCompletedQuiz {
  height: 350px;
  width: 350px;
  padding-top: 150px;
  font-size: 35px;
  color: rgb(1, 20, 8);
  font-weight: bold;
  text-align: center;
  background-color: rgb(200, 224, 93);
  border-radius: 50%;
  display: inline-block;
  cursor: auto;
}

.repeatBlockButtons{
  display: flex;
  flex-direction: column;
}

.repeatBlockButtons2{
  height: 140px;
  margin-top: 150px;
  border-radius: 20px;
}

.repeatBlockButtonsSeperate{
  display: flex;
  flex-direction: column;
}

.repeatButton{
  margin-left: 15px;
  text-decoration: none;
  border-bottom: 1px solid 	#D3D3D3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #303030;
  width: 190px;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  background-color: whitesmoke;
}

.repeatButton2{
  margin-left: 15px;
  text-decoration: none;
  border-bottom: 1px solid 	#D3D3D3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #303030;
  width: 190px;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  background-color: whitesmoke;
  cursor: auto;
}

.repeatButton2:hover{
  color: #282828;
}

.wordsList{
  margin-top: 0px;
  margin-left: 15px;
  text-decoration: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: 	#303030;
  width: 190px;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  background-color: whitesmoke;
}

.repeatButton:hover{
  color: #585858;
}

.wordsList:hover{
  color: #585858;
}


.wordsList2{
  margin-top: 0px;
  margin-left: 15px;
  text-decoration: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: 	#303030;
  width: 190px;
  height: 70px;
  font-size: 20px;
  font-weight: bold;
  background-color: whitesmoke;
  cursor: pointer;
}

.wordsList2:hover{
  color: #303030;
}

.progress{
  margin-top: 300px;
  margin-left: -190px;
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: 	#303030;
  width: 190px;
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  background-color: whitesmoke;
}

.progressBox{
  text-decoration: none;
  border-radius: 10px;
  color: 	#303030;
  width: 30px;
  height: 35px;
  font-size: 20px;
  font-weight: bold;
  background-color: orange;
}

.emptyBox{
  margin-top: 150px;
  margin-left: 15px;
  width: 190px;
  height: 190px;
}

.progressRepeat{
  position: absolute;
  text-decoration: none;
  border-radius: 10px;
  margin-left: 25px;
  margin-top: -10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: 	#303030;
  width: 170px;
  height: 7px;
  font-size: 20px;
  font-weight: bold;
  background-color: white;
}

.progressBoxRepeat{
  text-decoration: none;
  border-radius: 10px;
  color: 	#303030;
  height: 7px;
  font-size: 20px;
  font-weight: bold;
  background-color: lightblue;
}

.liana{
    width: 200px;
    height: 200px;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: -215px;
    border-radius: 15px;
    border-color: black;
}

.main {
  width: 100%;
  height: 413px;
}

.main2 {
  width: 100%;
  height: 413px;
}

.background {
  margin-top: -150px;
  width: 100%;
  height: 563px;
}

.hskLink{
  margin-top: -393px;
  margin-left: 82.2%;
  display: block;
  height: 50px;
  width: 120px;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.hsk2Link{
  margin-left: 82.2%;
  display: block;
  height: 45px;
  width: 120px;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.hsk3Link{
  margin-left: 82.2%;
  display: block;
  height: 47px;
  width: 120px;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .hskLink {
    max-width: 10%;
  }
  .hsk2Link {
    max-width: 10%;
  }
  .hsk3Link {
    max-width: 10%;
  }
}

.myModal{
    display: flex;
    justify-content: center;
}

.text{
  margin-left: 5%;
  font-size: 70px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  color: green;
}

.list{
  display: flex;
  flex-direction: column;
  margin-left: -20px;
  flex-wrap: wrap;
}

.descriptionList{
  margin-top: -30px;
  min-height: 91px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 1049px) {
  .descriptionList {
      border-bottom: 2px solid black;
      padding-bottom: 40px;
      margin-top: 5px;
      padding-left: 15px;
      padding-right: 15px;
  }
  .list{
    margin-left: 0px;
  }
}

.mainWordList{
  font-family: Arial, Helvetica, sans-serif;
  margin-top: -25px;
  margin-bottom: -40px;
  font-size: 30px;
  font-weight: bold;
  width: 150px;
  overflow-wrap: break-word; 
}

.translationWordList{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  width: 150px;
  margin-top: 30px;
  overflow-wrap: break-word; 
}

.radicalsDescriptionList{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  margin-top: 30px;
  text-align: center;
  width: 200px;
  overflow-wrap: break-word; 
}

.radicalsTranslationList{
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: left;
  width: 220px;
  overflow-wrap: break-word; 
}

.wordDescriptionList{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin-bottom: -20px;
  text-align: left;
  width: 300px;
  overflow-wrap: break-word; 
}

.description{
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow-wrap: break-word; 
}

.mainWord{
  position: relative;
  margin-top: 50px;
  margin-bottom: -40px;
  font-size: 70px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mainWordTranscr{
  position: absolute;
  font-size: 17px;
  margin-top: 73px;
  color:  #1662b9;
}

.mainWordDiv{
  position: relative;
  width: 800px;
  margin-top: 50px;
  margin-bottom: -32px;
  font-size: 70px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.audioSVG{
  position: absolute;
  display: flex;
  flex-direction: row;
  margin-right: -87.4%;
  font-weight: normal;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1210px){
  .mainWordDiv {
    width: 80%
  }
  .audioSVG{
    margin-right: -110%;
  }
}

@media screen and (max-width: 800px){
  .audioSVG{
    margin-right: -100%;
  }
}

.mainWordDiv2{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.transcriptionSVG{
  position: absolute;
  margin-top: 29px;
  cursor: pointer;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.radicalsDescription{
  font-size: 25px;
  margin-top: -10px;
}

.radicalsDescriptionDiv{
  font-size: 25px;
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.radicalsDescriptionTranscr{
  position: absolute;
  color:  #1662b9;
  font-weight: bold;
  font-size: 15px;
  margin-top: 70px;
}

.radicalsTranslation{
  margin-top: -20px;
}

.translationWord{
  font-weight: bold;
  font-size: 40px;
  width: 90%;
}

.wordDescription{
  font-size: 25px;
  margin-bottom: -20px;
  width: 80%;
}

.radicalsInformation{
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
}

.testBlock{
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  width: 300px;
  margin-left: 245px;
}

.test{
  margin-top: -30px;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word{
  margin-top: 40px;
  text-align: center;
  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;
  width: 90%;
}

.testRadical{
  font-weight: bold;
  font-size: 20px;
}

.testRadicalDescription{
  margin-top: -20px;
  margin-bottom: -30px;
}

.instructionToType{
  margin-top: 80px;
  margin-bottom: -50px;
  font-size: 20px;
}

.space{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  text-align: center;
  flex-wrap: wrap;
  max-width: 1000px;
}
.boxes{
  margin-left: 30px;
  margin-right: 30px;
}
.vertical{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resultsBox{
  margin-top: 5px;
  margin-bottom: -35px;
  height: 30px;
  width: 800px;
  border-radius: 10px;
  background-color:#E0E0E0
}

@media screen and (max-width: 1210px){
  .resultsBox {
    max-width: 80%
  }
  .resultsShowing{
    max-width: 80%
  }
  .testRadicalDescription{
    max-width: 90%;
  }
}

.resultsShowing{
  background-color: aquamarine;
  border-radius: 10px;
  height: 30px;
  width: 300px;
}

.box{
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  padding-top: 13px;
  margin-top: 10px;
  width: 430px;
  height: 80px;
  border-radius: 10px;
  border: 2px solid 	#BEBEBE;
}

.box:hover{
  box-shadow: 10px 10px 5px #E0E0E0;
}

.specialBox{
  background-color: rgb(200, 224, 93);
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  padding-top: 13px;
  margin-top: 20px;
  width: 430px;
  height: 80px;
  border-radius: 10px;
  border: 2px solid 	#BEBEBE;
  box-shadow: 5px 5px 5px #E0E0E0;
}

.specialBox2{
  background-color: rgb(175,0,42);
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  padding-top: 13px;
  margin-top: 20px;
  width: 430px;
  height: 80px;
  border-radius: 10px;
  border: 2px solid 	#BEBEBE;
  box-shadow: 5px 5px 5px #E0E0E0;
}

@media screen and (max-width: 1210px){
  .box {
    width: 350px;
  }
  .specialBox{
    width: 350px;
  }
  .specialBox2{
    width: 350px;
  }
}

@media screen and (max-width: 540px){
  .testBlock{
    width: 270px;
    margin-left: 230px;
  }
}

@media screen and (max-width: 500px){
  .testBlock{
    margin-left: 130px;
    width: 170px;
  }
}

@media screen and (max-width: 450px){
  .testBlock{
    margin-left: 130px;
    width: 170px;
  }
  .box {
    width: 300px;
  }
  .specialBox{
    width: 300px;
  }
  .specialBox2{
    width: 300px;
  }
}

.button{
  margin-top: 25px;
  padding: 10px;
  width: 200px;
  border-radius: 15px;
}

.buttonListBox{
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  border: none;
  display: flex;
  align-items: flex-end;
  background-color: whitesmoke;

}

.buttonList{
  width: 50px;
  border-radius: 15px;
  border: none;
  background-color: lightgreen;
}

.buttonList2{
  width: 50px;
  border: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: lightgreen;
}


.congratulationsBlockDiv{
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.congratulationsBlock{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.learnedWords{
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.learnedWordsText{
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
}

.descriptionListCongrPage{
  margin-top: -10px;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow-wrap: break-word; 
  flex-wrap: wrap;
  margin-top: 30px;
}

.wordDescriptionListCongrPage{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin-bottom: -20px;
  text-align: left;
  width: 200px;
  overflow-wrap: break-word; 
}

.svgBlockCongrPage{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.repeatWordSvg{
  cursor: pointer;
}

.repeatWordSvgCursor{
  cursor: auto;
}

.repeatWordSvg{
  cursor: pointer;
}

.repeatWordSvg2{
  cursor: pointer;
}

.repeatWordText{
  display: none;
}

.learnWordText{
  display: none;
}

.divForRepeatWordSvg{
  position: relative;
}

.learnWordTextQuiz{
  display: none;
}

.divForRepeatWordSvg:hover .repeatWordText{
    position: absolute;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    background-color: #404040;
    border-radius: 7px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-top: 3px;
    font-size: 13px;
    color: white;
    width: 130px;
    margin-left: -55px;
    margin-top: -56px;
}

.notDisplayed:hover .learnWordText{
    position: absolute;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    background-color: #404040;
    border-radius: 7px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-top: 3px;
    font-size: 13px;
    color: white;
    width: 150px;
    margin-left: -40px;
    margin-top: -58px;
}

.divForLearnWordSvg:hover .learnWordTextQuiz{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 150px;
  margin-left: -60px;
  margin-top: -58px;
}

.repeatWordSvgAbsolute{
  cursor: pointer;
  position: absolute;
  margin-top: 255px;
  margin-left: 210px;
}

.repeatWordSvgAbsolute2{
  cursor: pointer;
  position: absolute;
  margin-top: 190px;
  margin-left: 210px;
}

.repeatWordSvgAbsolute svg path {
  fill: #505050; /* Default color */
}

.repeatWordSvgAbsolute:hover svg path {
  fill: #383838; /* Color on hover */
}

.repeatWordSvgAbsolute2 svg path {
  fill: #505050	; /* Default color */
}

.repeatWordSvgAbsolute2:hover svg path {
  fill: #383838; /* Color on hover */
}

.learnAgainSvg{
  margin-left: 15px;
  cursor: pointer;
}

.learnAgainSvg2{
  cursor: pointer;
}

.learnAgainSvgCursor{
  cursor: auto;
}

.learnAgainSvg2Cursor{
  margin-left: 15px;
  cursor: auto;
}

.greetingWord{
  font-size: 50px;
  font-weight: bold;
}

@media screen and (max-width: 405px) {
  .greetingWord {
    font-size: 40px;
  }
}

.buttonBackToTest{
  margin-top: 10px;
  padding: 10px;
  color: rgb(1, 20, 8);
  width: 200px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
  background-color: rgb(200, 224, 93);
  border: 2px solid;
}

.myAccount{
  display: flex;
  flex-direction: row;
}

.otherUserPageAbsoluteDiv{
  position: absolute;
  right: 8%;
  margin-top: 2%;
  width: 85px;
  display: flex;
  justify-content: space-evenly;
}

.otherUserPageAbsoluteDivUnblockUser{
  position: absolute;
  right: 13%;
  margin-top: 2%;
  display: flex;
  justify-content: center;
}

.svgCursorPointer{
  cursor: pointer;
}

.imageForAccount{
  display: none;
}

.imageForAccount2FriendsList{
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1;
  z-index: 1;
  position: relative;
}

.imageForAccount2{
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1;
  z-index: 1;
  position: relative;
}

.photoCommonDiv{
  display: flex;
}

.photoDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  aspect-ratio: 1;
  font-size: 35px;
  color: rgb(1, 20, 8);
  font-weight: bold;
  text-align: center;
  background-color: rgb(200, 224, 93);
  border-radius: 50%;
  border: 10px solid lightblue;
}

.photoFriendsList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  aspect-ratio: 1;
  font-size: 35px;
  color: rgb(1, 20, 8);
  font-weight: bold;
  text-align: center;
  background-color: white;
  border-radius: 50%;
}

.nameAccount{
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow-x: hidden;
  display: block;
  width: 135px;
}

.svgAccount{
  opacity: 0.6;
}

.information{
  margin-left: 4.30%;
  width: 57.7%;
  margin-top: 50px;
}

@media screen and (max-width: 1000px) {
  .photo {
    min-width: 230px;
    min-height: 230px;
    height: auto;
  }
  .imageForAccount2{
    min-width: 230px;
    min-height: 230px;
    height: auto;
  }
  .information{
    min-width: 300px;
  }
}

.buttonFormSubmit2MyAccount{
  width: 300px;
  color: white;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #696969;
  border: 1px solid #DCDCDC;
}

@media screen and (max-width: 675px) {
  .buttonFormSubmit2MyAccount {
    width: 200px;
  }
  .information{
    min-width: 200px;
  }
}

@media screen and (max-width: 490px) {
  .buttonFormSubmit2MyAccount {
    width: 150px;
  }
}

@media screen and (max-width: 560px) {
  .myAccount {
    display: flex;
    flex-direction: column;
  }
}

.informationContent{
  font-size: 25px;
}

.informationContentLevel{
  margin-left: 65px;
  font-size: 25px;
}

.informationContentAge{
  margin-left: 70px;
  font-size: 25px;
}

.editModal{
  display: none;
}

.turnX{
  font-weight: bold;
  font-size: 20px;
  border: none;
  display: flex;
  margin-left: 427px;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

.turnXAddFriend{
  margin-left: -10px;
  font-weight: bold;
  font-size: 20px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

.nameButton{
  width: 250px;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 5px;
}

.roomButton{
  width: 250px;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
}

.joinChatButton{
  margin-top: 10px;
  width: 250px;
  height: 40px;
  border-radius: 5px;
  background-color: #E0E0E0;
}

.sendMessageBox{
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  z-index: 4;
}

.message{
  border-radius: 5px;
}

.sendmessageButton{
  border-top: 5px solid rgb(82, 114, 196);
  border-left: rgb(82, 114, 196);
  border-right: rgb(82, 114, 196);
  border-bottom: rgb(82, 114, 196);
  color: rgb(82, 114, 196);
}

.chatPage{
  width: 100%;
}

.chat{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.responsiveDivInChat{
  display: none;
}

.chatBox{
  width: 750px;
  margin-top: -10px;
  margin-bottom: 20px;
  border: 7px solid rgb(82, 114, 196);
  border-radius: 10px;
}

@media screen and (max-width: 950px) {
  .chatBox {
    max-width: 80%
  }
}


.message-container {
  width: 100%;
  height: 100%;
}


.messageContent {
  width: auto;
  height: auto;
  min-height: 40px;
  max-width: 250px;
  background-color: cornflowerblue;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.messageMeta{
  font-size: 10px;
  display: flex;
}

.messageTime{
  margin-right: 3px;
}

#other {
  display: flex;
  justify-content: flex-start;
}

#other .messageContent {
  display: flex;
  justify-content: flex-start;
}

#other .messageMeta {
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
}

#you {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

#you .messageContent {
  justify-content: flex-start;
  background-color: #43a047;
}

#you .messageMeta {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.messageMeta #author {
  font-weight: bold;
}

.joinChat{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.chatBody{
  height: 250px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.chatBody::-webkit-scrollbar {
  width: 10px;
}

.chatBody::-webkit-scrollbar {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.chatBody::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 20px;
  background-clip: content-box;
}

.messageBox{
  resize: none;
  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
  border-top: 5px solid rgb(82, 114, 196);
  border-left: rgb(82, 114, 196);
  border-right: 5px solid rgb(82, 114, 196);
  border-bottom: rgb(82, 114, 196);
  padding-top: 17px;
  padding-left: 20px;
  padding-bottom: 5px;
  width: 1150px;
}

.messageBox:focus {
  outline: none;
}

.messageBox::-webkit-scrollbar {
  width: 8px;
}

.messageBox::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.messageBox::-webkit-scrollbar-thumb {
  background-color:  #cccccc; 
  border-radius: 20px;
  border: 6px solid  #cccccc; 
  background-clip: content-box;
}

.columnRegisterPage{
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
}

.loginStatusMessage{
  color: red;
  font-weight: bold;
  margin-bottom: -10px;
}

.login{
  margin-top: 20px;
  width: 350px;
  height: 300px;
}

.loginStatusDivWrong{
  font-size: 20px;
  color: red;
}

.loginStatusDivRight{
  font-size: 20px;
  color: green;
}

.loginStatusDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.divResendCode{
  cursor: pointer;
  color: gray;
  margin-top: 5px;
  margin-bottom: -4px;
}

.register{
  width: 500px;
  height: 350px;
}

.registerForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  border-radius: 20px;
  background-color: #73C2FB;
  box-shadow: 5px 5px 5px #E0E0E0;
}

.error{
  position: absolute;
  background-color: whitesmoke;
  margin-top: 82px;
  font-size: 12px;
  border-radius: 10px;
}

.divInputAndErrorChatName{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorNameOfChat{
  position: absolute;
  z-index: 2;
  width: max-content;
  background-color: whitesmoke;
  margin-top: 125px;
  font-size: 12px;
  border-radius: 10px;
}

.errorEditMyAccountInfo{
  position: absolute;
  z-index: 2;
  width: max-content;
  background-color: whitesmoke;
  margin-top: 43px;
  font-size: 12px;
  border-radius: 10px;
}

.errorEditMyAccountInfoTextArea{
  position: absolute;
  z-index: 2;
  width: max-content;
  background-color: whitesmoke;
  margin-top: 154px;
  font-size: 12px;
  border-radius: 10px;
}

.errorMessageWriting{
  position: absolute;
  z-index: 2;
  width: max-content;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  background-color: #404040;
  color: white;
  margin-top: 95px;
  font-size: 12px;
}

.registerFormColumn{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  border-radius: 20px;
  background-color: #73C2FB;
  box-shadow: 5px 5px 5px #E0E0E0;
}

.buttonForm{
  width: 300px;
  height: 36px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: 	#E8E8E8;
}

.buttonFormName{
  width: 300px;
  height: 36px;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: 	#E8E8E8;
}

.buttonFormPassword{
  width: 300px;
  height: 36px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 30px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: 	#E8E8E8;
}

.switchRegisterButtonGoToLogin{
  width: 235px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
  border: 1px solid #E8E8E8;
  cursor: pointer;
}

.switchRegisterButtonGoToRegister{
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
  border: 1px solid #E8E8E8;
  cursor: pointer;
}

.registerWithGoogleButton{
  width: 235px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
  border: 1px solid #E8E8E8;
  cursor: pointer;
}

.loginWithGoogleButton{
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: whitesmoke;
  border: 1px solid #E8E8E8;
  cursor: pointer;
}

.rowButtonsRegister{
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.rowButtonsLogin{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 350px;
}

.switchRegisterButtonSpan{
  opacity: 0.9;
}

.googleSignSvg{
  margin-right: 3px;
}

@media screen and (max-width: 570px){
  .register {
    width: 80%;
  }
  .rowButtonsRegister{
    width: 80%;
  }
}

.buttonFormAccountInfo{
  margin-bottom: 10px;
  width: 300px;
  height: 40px;
  border: 2px solid black;
  font-size: 25px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: 	#E8E8E8;
  font-family: 'Times New Roman', Times, serif;
}

.buttonFormAccountInfo::placeholder{
  font-family: 'Times New Roman', Times, serif;
}

.buttonFormAccountInfoTextArea{
  margin-bottom: 10px;
  width: 300px;
  height: 150px;
  resize: none;
  font-size: 25px;
  border-radius: 10px;
  border: 2px solid black;
  margin-top: 5px;
  background-color:#E8E8E8;
  font-family: 'Times New Roman', Times, serif;
}

.buttonFormAccountInfoTextArea::placeholder{
  font-family: 'Times New Roman', Times, serif;
}

.buttonFormAccountInfoDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonFormSubmit{
  width: 300px;
  color: white;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #696969;
  border: 1px solid #E8E8E8;
}

.buttonFormSubmit2{
  width: 300px;
  color: white;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #696969;
  border: 1px solid #DCDCDC;
}

.buttonFormSubmit2RegisterPage{
  width: 300px;
  color: white;
  font-size: 20px;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #696969;
  border: 1px solid #DCDCDC;
}


.buttonFormSubmitRegister{
  width: 300px;
  font-size: 20px;
  color: white;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #696969;
  border: 1px solid #E8E8E8;
}

.buttonFormNickNameRegister{
  width: 300px;
  height: 36px;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: 	#E8E8E8;
}

.buttonFormSubmitSave{
  width: 300px;
  color: white;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #696969;
  border: 1px solid #DCDCDC;
}

.label{
  width: 300px;
  margin-top: 10px;
  font-weight: bold;
}

.formColumn{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.threeDots{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  margin-top: 200px;
  margin-left: 20px;
  border: none;
}

@media screen and (max-width: 430px) {
  .threeDots{
    margin-left: 0px;
  }
  .hskDot{
    margin-left: -225px;
  }
  .noLink{
    margin-left: -225px;
  }
}

@media screen and (max-width: 412px) {
  .threeDots{
    margin-left: -5px;
  }
  .hskDot{
    margin-left: -230px;
  }
  .noLink{
    margin-left: -230px;
  }
}
@media screen and (max-width: 385px) {
  .threeDots{
    margin-left: -13px;
  }
  .hskDot{
    margin-left: -238px;
  }
  .noLink{
    margin-left: -238px;
  }
}

.blackDot {
  width: 10px;
  height: 10px;
  border: 2px solid #383838;
  border-radius: 50%;
  background-color: #383838;
  margin-bottom: 2px;
}

.column{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.columnAddFriend{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.columnChats{
  position: relative;
  display: flex;
  margin-top: -50px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 210px;
  height: 357px;
}

.columnChatsResponsive{
  display: flex;
  margin-top: -87px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 210px;
  height: 357px;
}

.columnChatsResponsivePrivate{
  display: flex;
  margin-top: -80px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 210px;
  height: 357px;
}

@media screen and (max-width: 1210px){
  .columnChats {
    margin-top: 0px;
  }
}

.inputPhoto2{
  display: none;
}

.inputPhotoText{
  display: none;
}

.myAccountPage{
  margin-bottom: 40px;
  padding-left: 3.95%;
  padding-right: 3.95%;
}

.addFriendText{
  justify-self: center;
  border: none;
  font-size: 40px;
  width: 300px;
}

.addFriendText2{
  margin-left: 20px;
  justify-self: center;
  border: none;
  font-size: 40px;
  width: 300px;
}

.notDisplayed{
  display: none;
}

.typeUsername{
  margin-top: -10px;
  display: flex;
  justify-content: center;
}

.friendsAddingAndList{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.friendsAddingAndListCommon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.friendsAddingAndListCommonP{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.friendsList{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.friendsListMargin{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.nameText{
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
}

.friendAccountLink{
  margin-right: 30px;
  margin-bottom: 20px;
  width: 200px;
  height: 60px;
  padding: 5px;
  border-radius: 10px;
  background-color: #98ae34;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.row{
  display: flex;
  flex-direction:row;
}

.friendsRequest{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttonFormConfirmRequest{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border: none;
  font-weight: bold;
  background-color: pink;
  border-radius: 20px;
  margin-left: 100px;
  margin-right: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.displayColumn{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.friendsNameRequests{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.friendNameInRequest{
  font-size: 20px;
  cursor: pointer;
}

.removeRequest{
  border: 2px solid;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 25px;
}

#message2{
  display: none;
}

.removeButtonNone{
  display: none;
}

.removeButton{
  display: block;
  font-size: 15px;
  width: 70px;
  height: 30px;
  border-radius: 20px;
}

.linkToUsersPageButton{
  display: block;
  font-size: 15px;
  width: 100px;
  height: 30px;
  border-radius: 20px;
}

.removeButtonX{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  width: 17px;
  height: 17px;
  border: none;
}

.formAccountEdit{
  margin-top: -5px;
  display: flex;
  flex-direction: column;
}

.editModal2{
  display: flex;
}

.editModalColumn{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 435px;
  height: 404.33px;
  margin-top: -30px;
  margin-left: -40px;
}

.editModalColumnResponsive{
  display: none;
}

@media screen and (max-width: 800px) {
  .editModalColumn {
    display: none;
  }
  .editModalColumnResponsive{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 435px;
    height: 404.33px;
  }
}

@media screen and (max-width: 510px){
  .buttonFormAccountInfo {
    width: 200px;
  }
  .buttonFormAccountInfoTextArea{
    width: 200px;
  }
  .buttonFormSubmitSave{
    width: 200px;
  }
  .turnX{
    margin-left: 340px;
  }
}

@media screen and (max-width: 400px){
  .editModalColumnResponsive{
    width: max-content;
  }
  .buttonFormAccountInfo {
    width: 150px;
  }
  .buttonFormAccountInfoTextArea{
    width: 150px;
  }
  .buttonFormSubmitSave{
    width: 150px;
  }
  .turnX{
    margin-left: 290px;
  }
}


.threeDotsRemoveButton{
  display: none;
}

.message:hover > .threeDotsRemoveButton{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7px;
  height: 20px;
  border: none;
}

.blackDotRemoveButton {
  width: 3px;
  height: 3px;
  border: 2px solid #383838;
  border-radius: 50%;
  background-color: #383838;
  margin-bottom: 2px;
}

.threeDotsRemoveButtonOnClick{
  display: none;
}

.none{
  display: none;
}

.chatNotification{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.chatNotification2{
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.svgButton{
  position: absolute;
  margin-left: 75px;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.svgButtonResponsive{
  position: absolute;
  margin-left: 75px;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.inviteBoxNone{
  display: none;
}

.inviteBox{
  margin-left: 305px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: start;
  position: absolute;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 50px;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 10px;
}

@media screen and (max-width: 1210px){
  .inviteBox {
    margin-top: 30px;
    margin-left: auto;
  }
}

.invitation{
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.joinButtonsDiv{
  display: flex;
  margin-left: 3px;
  margin-right: 3px;
}

.joinButton{
  margin-left: auto;
  margin-right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightblue;
  border: none;
  border-radius: 10px;
  width: 45px;
  height: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.joinButtonX{
  margin-top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10px;
  width: 15px;
  height: 15px;
}

.loginPage{
  position: relative;
  z-index: -1;
}

.inviteFriendText{
  display: flex;
  margin-top: -70px;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 40px;
  height: 30px;
}

.inviteFriendText2{
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 40px;
  width: 50px;
  height: 30px;
}

.friendsInviting{
  position: relative;
  display: flex;
  width: 210px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: 15px;
  z-index: 5;
}

.commonDiv{
    display: flex;
    width: 185px;
    height: 95px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.commonDivNotDisplayed{
  visibility: hidden;
  width: 185px;
  height: 95px;
  background-color: #034694;
}

.column{
  display: flex;
  flex-direction: column;
}

.chats{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 250px;
  margin-left: 20px;
  overflow-y: scroll; 
  margin-top: 5px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.chats::-webkit-scrollbar {
  width: 10px;
}

.chats::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.chats::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 6px solid #a8bbbf;
  background-clip: content-box;
}

.textChats{
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
}

.chatButton{
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8c91ab;
  border: none;
  border-radius: 10px;
  width: 130px;
  height: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  cursor: pointer;
  color: black;
}

.chatButtonCurrent{
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #5e6582;
  color: white;
  border: none;
  border-radius: 10px;
  width: 130px;
  height: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .friendsInviting {
    display: flex;
  }
}

@media screen and (max-width: 1000px) {
  .friendsInvitingBox {
    display: none;
  }
}

.buttonFormInvite{
  position: relative;
  z-index: 1;
  width: 150px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: white;
}

.buttonFormSubmit2Invite{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  color: black;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  background-color: lightblue;
  border: 1px solid #DCDCDC;
}

.buttonFormRandomSearch{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 150px;
  height: 30px;
  color: black;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 0px;
  background-color: pink;
  border: 1px solid #DCDCDC;
}

.turnXInviteFriend{
  margin-top: -10px;
  font-weight: bold;
  font-size: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}

.rowInvite{
  display: flex;
  flex-direction:row;
}

.plus{
  border: none;
}

.alertDivRegisterMain {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  top: 120px;
  height: 140px;
  z-index: 1;
  border-radius: 12px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.alertDivRegister {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  top: 120px;
  height: 130px;
  z-index: 1;
  border-radius: 12px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.alertDivRegister3 {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  top: 120px;
  z-index: 1;
  border-radius: 12px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.alertDivRegisterMain:hover {
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  top: 120px;
}

.alertDivRegisterMain .close:hover {
  opacity: 1;
}

.alertDivRegister:hover {
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  top: 120px;
}

.alertDivRegister .close:hover {
  opacity: 1;
}

.checkRegister {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  background-color: green;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.textDivRegister {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

.messageDiv .textDivRegister.textDiv-1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: darkgreen;
}

.checkEmailMessage{
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.alertDiv {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  top: 235px;
  z-index: 1;
  margin-top: -130px;
  border-radius: 12px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.alertDivBlack {
  position: absolute;
  margin-top: 50px;
  margin-left: 7px;
  height: 70px;
  z-index: 1;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 210px;
  overflow: hidden;
}

.alertDivBlackQuiz {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  margin-top: 65px;
  z-index: 1;
  height: 70px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 210px;
  overflow: hidden;
}

.alertDiv-content {
  display: flex;
  align-items: center;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  background-color: #4070f4;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.messageDiv {
  height: 72px;
  width: 213.67px;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.messageDivWrong{
  height: 72px;
  width: 213.67px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 20px;
}

.textDiv {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.messageDiv .textDiv.textDiv-1 {
  font-weight: 600;
  color: #333;
}

.alertDiv:hover {
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  top: 235px;
}

.close {
  position: absolute;
  font-size: 14px;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
  border: none;
}

.alertDiv .close:hover {
  opacity: 1;
}

.alertDiv .progressDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;

}

.alertDiv .progressDiv:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #4070f4;
}

@keyframes progressDiv {
  0% {
    right: 0%;
  }
  100% {
    right: 100%;
  }
}

.alertDiv .progressDiv.active:before {
  animation: progressDiv 5000ms linear forwards;
}

.checkWrong {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  background-color: orange;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.friendsInvitingBox{
  margin-right: 10px;
  width: 210px;
  display: flex;
  justify-content: flex-end;
}

.friendsInvitingBoxResponsive{
  margin-right: 10px;
  width: 210px;
  display: flex;
  justify-content: flex-end;
}

.usersListSign{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  height: 35px;
  width: 35px;
  font-size: 40px;
  background-color: #5e6582;
  color: #fff;
  border-radius: 50%;
  border: none;
}

.chatUsers{
  display: flex;
  flex-direction: column;
  background-color: hsl(254, 38%, 73%);
  align-items: center;
  width: 150px;
  height: 250px;
  overflow-y: scroll; 
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.chatUsers::-webkit-scrollbar {
  width: 10px;
}

.chatUsers::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.chatUsers::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
  border: 6px solid #a8bbbf;
  background-clip: content-box;
}

.chatButtonUsersList{
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #F5F5F5;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  width: 130px;
  height: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
}

.friendInChatDiv{
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow-x: hidden;
  display: block;
}

.chatButtonUsersListCurrent{
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #F5F5F5;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 130px;
  height: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.chatUsersNotDisplayed{
  display: none;
}

.friendsInvitingBoxNotDisplayed{
  margin-right: 10px;
  width: 210px;
  display: flex;
  justify-content: flex-end;
}

.friendsInvitingBoxNotDisplayedResponsive{
  margin-right: 10px;
  width: 210px;
  display: flex;
  justify-content: flex-end;
}

.friendsInvitingBoxNotDisplayed2Responsive{
  display: none;
}

.friendsInvitingBoxNotDisplayed2{
  margin-right: 10px;
  width: 210px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1210px){
  .chat {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .chatUserPrivate{
    border-radius: 10px;
    margin-top: -20px;
  }

  .chatBox{
    order: 1;
  }

  .columnChats{
    display: none;
  }

  .friendsInvitingBox{
    display: none;
  }

  .friendsInvitingBoxNotDisplayed{
    display: none;
  }

  .responsiveDivInChat{
    width: 100%;
    order: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .friendsInvitingBoxNotDisplayed2{
    display: none;
  }

  .friendsInvitingBoxNotDisplayed2Responsive{
    order: 2;
    width: 210px;
    display: flex;
    justify-content: center;
    z-index: 3;
  }

}

.turnXChatFriendList{
  font-weight: bold;
  font-size: 15px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 20px;
}

.svgButtonMessage{
  z-index: 1;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.chatNumber{
  margin-right: 11px;
  margin-left: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow-x: hidden;
  display: block;
}

.removeChatButton{
  margin-right: -5px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.threeDotsRemoveButtonChat{
  width: 32px;
  margin-right: -5px;
  height: 20px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  opacity: 0;
}

.threeDotsRemoveButtonChatMyAccount{
  position: absolute;
  right: 8%;
  margin-top: 2%;
  width: 85px;
  height: 25px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: none;
}

.blackDotRemoveButtonMyAccount {
  width: 6px;
  height: 6px;
  border: 2px solid #383838;
  border-radius: 50%;
  background-color: #383838;
  margin-bottom: 2px;
}

@media screen and (max-width: 1100px) {
  .threeDotsRemoveButtonChatMyAccount{
    align-items: flex-end;
    right: 3%;
  }
  .otherUserPageAbsoluteDiv{
    right: 3%;
  }
  .otherUserPageAbsoluteDivUnblockUser{
    right: 3%;
  }
}

.chatButton:hover > .threeDotsRemoveButtonChat{
  opacity: 1;
}

.chatButtonCurrent:hover > .threeDotsRemoveButtonChat{
  opacity: 1;
}

.askingBoxNone{
  display: none;
}

.askingBox{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 280px;
  height: 170px;
  border-radius: 20px;
  display: flex;
  position: fixed;
  background-color: #fff;
  z-index: 1;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

.pRemove{
  font-size: 20px;
}

.pRemove2{
  font-size: 13px;
  margin-top: -15px;
}

.pRemovePage{
  font-size: 20px;
  margin-bottom: 0px;
}

.pRemovePageDescription{
  font-size: 12px;
  margin-bottom: 5px;
  letter-spacing: 0.05em;
  line-height: 1.2;
}

.pRemovePageDescriptionQuiz{
  font-size: 12px;
  align-self: start;
  margin-bottom: 5px;
  letter-spacing: 0.05em;
  line-height: 1.2;
}

.answerRemove{
  display: flex;
  width: 200px;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  font-size: 20px;
}

.yesAnswer{
  border: none;
  background-color:  #B22222;
  opacity: 0.9;
  color: white;
  border-radius: 10px;
  width: 50px;
}

.yesAnswer:hover {
  opacity: 1;
}

.noAnswer{
  border: none;
  background-color: #034694;
  opacity: 0.9;
  color: white;
  border-radius: 10px;
  width: 50px;
}

.noAnswer:hover {
  opacity: 1;
}

.yesAnswerRenameChat{
  border: none;
  background-color:  green;
  opacity: 0.9;
  color: white;
  border-radius: 10px;
  width: 70px;
}

.yesAnswerRenameChat:hover {
  opacity: 1;
}

.noAnswerRenameChat{
  border: none;
  background-color: #1f436c;
  opacity: 0.9;
  color: white;
  border-radius: 10px;
  width: 70px;
}

.noAnswerRenameChat:hover {
  opacity: 1;
}

.renameChatInput{
  border-radius: 10px;
  margin-bottom: 5px;
  width: 200px;
}

.pRename{
  font-size: 20px;
  margin-bottom: 10px;
}

.positionAlertDiv{
  margin-top: -50px;
  margin-left: -180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chatUserPrivate{
  display: flex;
  flex-direction: column;
  background-color: hsl(254, 38%, 73%);
  align-items: center;
  width: 150px;
  height: 80px;
  margin-right: -10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.emptyList{
  display: flex;
  height: 30px;
  width: 130px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}

.emptyList2{
  display: flex;
  height: 150px;
  width: 130px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.settingsPage{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 430px;
  min-height: 430px;
}

.settingsPageCommonDiv{
  margin-top: -10px;
  width: 600px;
  height: 355px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
}

.settingsPageContentDiv{
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 54px;
}

.settingsPageForm{
  width: 500px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settingsPageFormDiv{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.divForm{
  margin-bottom: 10px;
}

.selectSettingsDivForm{
  width: 150px;
  margin-left: 20px;
  border-radius: 10px;
  background-color: white;
}

.settingsSubmitButton{
  border-radius: 10px;
  color: white;
  font-size: 20px;
  width: 100%;
  border: none;
  background-color: green;
}

.settingsDivButtons{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settingsDivButtonsRow{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
}

.logOut{
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: rgb(142, 56, 70);
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
}

.changePasswordSettings{
  margin-top: 20px;
  display: flex;
  padding-left: 11.8px;
  padding-right: 11.8px;
  justify-content: center;
  color: white;
  background-color: #2d64a3;
  width: 240px;
  border-radius: 10px;
  cursor: pointer;
}

.disabledChangePasswordSettings{
  margin-top: 20px;
  display: flex;
  padding-left: 11.8px;
  padding-right: 11.8px;
  justify-content: center;
  color: white;
  background-color: #2d64a3;
  width: 240px;
  border-radius: 10px;
  cursor: not-allowed;
}

.changeEmailSettings{
  padding-left: 11.8px;
  padding-right: 11.8px;
  height: 24px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: #2d64a3;
  width: 240px;
  border-radius: 10px;
  cursor: pointer;
}

.disabledChangeEmailSettings {
  padding-left: 11.8px;
  padding-right: 11.8px;
  height: 24px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: #2d64a3;
  width: 240px;
  border-radius: 10px;
  cursor: not-allowed;
}

.divDisabledChangingEmail{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 200px;
  margin-left: 20px;
  margin-bottom: 30px;
}

.divDisabledChangingPassword{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 200px;
  margin-left: 280px;
  margin-bottom: 30px;
}

.divDisabledSettings{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 200px;
  margin-left: 20px;
  margin-top: 85px;
}

.removePageSettings{
  margin-bottom: 5px;
  padding-left: 11.8px;
  padding-right: 11.8px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: 	#505050;
  width: 120px;
  border-radius: 10px;
  cursor: pointer;
}


@media screen and (max-width: 660px) {
  .settingsPageCommonDiv{
    width: 90%;
  }
  .settingsPageForm{
    width: 92%;
  }
  .settingsSubmitButton{
    width: 69.9%;
  }
  .settingsPageContentDiv{
    width: 100%;
    align-items: center;
  }
  #question3{
    width: 70px;
    margin-left: 5px;
  }
  #question4{
    width: 70px;
    margin-left: 5px;
  }
  .selectSettingsDivForm{
    width: 115px;
  }
  .settingsDivButtons{
    width: 80.5%;
  }
  .changePasswordSettings{
    width: 150px;
  }
  .changeEmailSettings{
    width: 150px;
  }
}

@media screen and (max-width: 465px) {
  .settingsPageCommonDiv{
    width: 90%;
  }
  .settingsPageForm{
    width: 92%;
  }
  .settingsSubmitButton{
    width: 69.9%;
  }
  .settingsPageContentDiv{
    width: 100%;
    align-items: center;
  }
  #question1{
    width: 49px;
    margin-left: 5px;
  }
  #question2{
    width: 49px;
    margin-left: 5px;
  }
  #question3{
    width: 49px;
    margin-left: 5px;
  }
  #question4{
    width: 49px;
    margin-left: 5px;
  }
  .selectSettingsDivForm{
    width: 115px;
  }
  .settingsDivButtons{
    width: 80.5%;
  }
  .changePasswordSettings{
    width: 150px;
  }
  .changeEmailSettings{
    width: 150px;
  }
}

@media screen and (max-width: 435px) {
  .settingsPageCommonDiv{
    width: 90%;
  }
  .settingsPageForm{
    width: 92%;
  }
  .settingsSubmitButton{
    width: 69.9%;
  }
  .settingsPageContentDiv{
    width: 100%;
    align-items: center;
  }
  #question1{
    width: 49px;
    margin-left: 5px;
  }
  #question2{
    width: 40px;
    margin-left: 5px;
  }
  #question3{
    width: 40px;
    margin-left: 5px;
  }
  #question4{
    width: 40px;
    margin-left: 5px;
  }
  .selectSettingsDivForm{
    width: 115px;
  }
  .settingsDivButtons{
    width: 80.5%;
  }
  .changePasswordSettings{
    width: 150px;
  }
  .changeEmailSettings{
    width: 150px;
  }
  .logOut{
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 435px) {
  .settingsPageCommonDiv{
    height: max-content;
  }
}

.notFoundPage{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 290px;
  justify-content: center;
  align-items: center;
}

.notFoundPagePayedCourse{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 435px;
  justify-content: center;
  align-items: center;
}

.notFoundText{
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0.8;
}

.verifyPage{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 285px;
}

@media screen and (max-height: 285px) {
  .verifyPage {
      height: 100vh;
  }
}

.verifyPageText{
  font-size: 30px;
  color: green;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.verifyPageTextRed{
  font-size: 30px;
  color: red;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;;
}

.verifyPageTextBlack{
  font-size: 30px;
  font-weight: bold;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;;
}

.verifyPageGoToLogin{
  background-color: #034694;
  color: white;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 20px;
  margin-top: 20px;
}

.changeEmailPage{
  height: 413px;
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.changeEmailFormColumn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.formAccountChangeEmail{
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color:  #abc4ee;
  width: 450px;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
}

.errorChangeEmail{
  position: absolute;
  background-color: whitesmoke;
  margin-top: 90px;
  font-size: 12px;
  border-radius: 10px;
}

.labelChangeEmail{
  width: 300px;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

.buttonFormSubmitChangeEmail{
  width: 300px;
  color: white;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: green;
  border: 1px solid #E8E8E8;
}

.changePasswordPage{
  width: 100%;
  height: 413px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.recoverPasswordChangePasswordPage{
  margin-bottom: 50px;
  margin-top: 10px;
  color: gray;
}

.recoverPasswordRegisterPage{
  color: gray;
  margin-top: 7px;
}

.recoverPasswordChangePasswordPageSpan{
  text-decoration: underline;
  cursor: pointer;
}

.formAccountChangePassword{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color:   #abc4ee;;
  width: 450px;
  height: 350px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
}

.formAccountChangePassword2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color:   #abc4ee;;
  width: 450px;
  height: 350px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  margin-bottom: 90px;
}

.labelChangePassword{
  width: 300px;
  font-size: 23px;
  text-align: center;
  font-weight: bold;
}

.labelChangePassword2{
  width: 300px;
  font-size: 23px;
  text-align: center;
  font-weight: bold;
  margin-top: -20px;
}

.buttonFormChangePassword{
  width: 300px;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 30px;
  border-radius: 10px;
  margin-top: 5px;
  background-color: 	#E8E8E8;
}

.svgInfo{
  position: absolute;
  margin-left: 31px;
  cursor: pointer;
}

.informationAboutChat{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 210px;
  z-index: 5;
}

@media screen and (max-width: 470px){
  .informationAboutChat{
    width: 190px;
  }
}

@media screen and (max-width: 415px){
  .informationAboutChat{
    width: 170px;
  }
}

@media screen and (max-width: 450px){
  .friendsInvitingBoxResponsive {
    margin-right: 0px;
  }
  .friendsInvitingBoxNotDisplayedResponsive{
    margin-right: 0px;
  }
}

@media screen and (max-width: 435px){
  .friendsInvitingBoxResponsive {
    width: 190px;
  }
  .friendsInvitingBoxNotDisplayedResponsive{
    width: 190px;
  }
  .chatUsers{
    width: 130px;
  }
  .chatButtonUsersList{
    width: 110px;
  }
  .chats{
    width: 130px;
  }
  .chatButton{
    width: 110px;
  }
  .chatButtonCurrent{
    width: 110px;
  }
  .chatNumber{
    margin-right: 0px;
  }
  .columnChatsResponsive{
    width: 190px;
  }
  .emptyList{
    width: 110px;
  }
  .emptyList2{
    width: 110px;
  }
  .buttonFormInvite{
    width: 130px;
  }
  .buttonFormSubmit2Invite{
    width: 130px;
  }
  .buttonFormRandomSearch{
    width: 130px;
  }
  .commonDiv{
    width: 145px;
  }
  .turnXInviteFriend{
    margin-left: -5px;
  }
  .friendsInviting{
    width: 180px;
  }
  .inviteFriendText{
    width: 27px;
  }
}

@media screen and (max-width: 390px){
  .friendsInvitingBoxResponsive {
    width: 180px;
  }
  .friendsInvitingBoxNotDisplayedResponsive{
    width: 180px;
  }
  .chatUsers{
    width: 120px;
  }
  .chatButtonUsersList{
    width: 100px;
  }
  .chats{
    width: 120px;
  }
  .chatButton{
    width: 100px;
  }
  .chatButtonCurrent{
    width: 100px;
  }
  .chatNumber{
    margin-right: -10px;
  }
  .columnChatsResponsive{
    width: 180px;
  }
  .emptyList{
    width: 100px;
    font-size: 14px;
  }
  .emptyList2{
    width: 100px;
    font-size: 14px;
  }
  .buttonFormInvite{
    width: 120px;
  }
  .buttonFormSubmit2Invite{
    width: 120px;
  }
  .buttonFormRandomSearch{
    width: 120px;
  }
  .commonDiv{
    width: 135px;
  }
  .friendsInviting{
    width: 170px;
  }
  .inviteFriendText{
    width: 27px;
  }
  .textChats{
    font-size: 14px;
  }
  .buttonFormRandomSearch{
    font-size: 14px;
  }
  .informationAboutChat{
    width: 165px;
  }
}


.footerDiv{
  width: 100%;
  background-color: gray;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px;
  padding-right: 30px;
  font-size: 17px;
}

.footerDivColumn{
  display: flex;
  flex-direction: column;
  margin-right: 70px;
  width: 100px;
}

.footerLink1{
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.footerLink2{
  color: white;
  text-decoration: none;
}

.footerLink3{
  color: white;
  text-decoration: none;
}

.footerLink1:hover{
  font-weight: bold;
  color: white;
  }

.footerLink2:hover{
  font-weight: bold;
    color: white;
    }

.footerLink3:hover{
  font-weight: bold;
      color: white;
      }

      @media screen and (max-width: 1000px) {
        .footerDiv{
          padding-left: 40px;
          padding-right: 20px;
          font-size: 17px;
        }
      }

      @media screen and (max-width: 600px) {
        .footerDiv{
          padding-left: 20px;
          padding-right: 20px;
          font-size: 17px;
        }
        .footerDivColumn{
          margin-right: 30px;
        }
      }

.contactPage{
  width: 100%;
  margin-top: -10px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactInfoDiv{
  display: flex;
  flex-direction: column;
}

.contactPageDiv{
  display: flex;
  flex-direction: column;
  font-size: 20px;
  width: 90%;
}

.aboutPageInfoParagraph1{
  margin-bottom: 5px;
  display: inline-block;
  text-align: justify;
  text-indent: 30px; /* Adjust the margin as needed */
    
}

.aboutPageInfoParagraph2{
  margin-bottom: 35px;
  display: inline-block;
  text-align: justify;
  text-indent: 30px; /* Adjust the margin as needed */
}

.aboutPageInfoParagraphChatInfo{
  margin-top: 30px;
  display: inline-block;
  text-align: justify;
  text-decoration: underline;
}

.paragraphInfoDiv{
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.aboutPageColumn{
  display: flex;
  flex-direction: column;
}

.payment-formPage {
  width: 100%;
  min-height: 455px;
  display: flex;
  flex-direction: column;
}

.payment-form {
  width: 100%;
  max-width: 400px; /* Adjust max-width as needed */
  margin: 0 auto; /* Center the form */
  margin-top: -25px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.stripe-element-container {
  margin-bottom: 20px; /* Add some space below the PaymentElement */
}

.payment-button {
  background-color: #007bff; /* Blue color, adjust as needed */
  color: #fff; /* White text color */
  border: none;
  border-radius: 4px;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  margin-top: 30px;
}

.payment-button:hover {
  background-color: #0056b3; /* Darker blue color on hover, adjust as needed */
}

.payment-button:disabled {
  background-color: #007bff; /* Light gray background for disabled state */
  cursor: not-allowed; /* Change cursor to not-allowed for disabled state */
}

.text-black {
  display: flex;
  justify-content: center;
 font-family: serif;
 font-weight: bold;
 color: #000; /* Black text color */;
}

.succededPayment{
  width: 360px;
  display: flex;
  justify-content: center;
  color: green;
  margin-top: 15px;
  font-weight: bold;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 17px;
}

.succededPaymentDate{
  display: flex;
  justify-content: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.succededPaymentDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.succededPaymentDivPaypal{
  margin-bottom: -62px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper{
  position: relative;
  margin-top: -50px;
  width: 100%;
  height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonsPaypal{
  width: 300px;
}

.succededPaymentPaypalOld{
  position: absolute;
  top: 265px;
  width: 360px;
  display: flex;
  justify-content: center;
  color: green;
  margin-top: 15px;
  font-weight: bold;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 17px;
}

.textToSpeechRepeatPage{
    margin-top: 60px;
    margin-bottom: -10px;
    width: 60px;
    height: 60px;
}

.checkDone {
  position: absolute;
  display: flex;
  margin-top: -50px;
  margin-left: 150px;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.checkDoneList {
  position: absolute;
  display: flex;
  margin-top: 15px;
  margin-left: 63px;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.checkDoneList2 {
  position: absolute;
  display: flex;
  margin-top: 15px;
  margin-left: 25px;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.checkDoneListRepeat {
  position: absolute;
  display: flex;
  margin-top: 15px;
  margin-left: -15px;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.checkDoneList2Repeat {
  position: absolute;
  display: flex;
  margin-top: 15px;
  margin-left: 25px;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.waiting{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.threeDotsWaiting{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-bottom: 100px;
}

.blackDotWaiting {
  width: 15px;
  height: 15px;
  border: 2px solid gray;
  border-radius: 50%;
  background-color: gray;
  margin-left: 5px;
}

.payingQuestionPage{
  background-color: #0056b3;
  min-height: 585px;
  margin-top: -30px;
  display: flex;
  justify-content: center;
}

@media screen and (min-height: 670px) {
  .payingQuestionPage {
      height: 100vh;
  }
}


.payingMethodPage{
  height: 420px;
  margin-top: -30px;
  display: flex;
  justify-content: center;
}

.theMethodPage{
  height: 220px;
  margin-top: 100px;
}

.paymentMethodDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payMethodText{
  opacity: 0.9;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;  
}

.buttonPayMethod{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 40px;
  border-radius: 15px;
  text-decoration: none;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: 	#505050;
  border: 2px solid;
}

.buttonPayMethodChangeMethod:hover {
  background-color: #d5d5d5;
}

.buttonPayMethodPayPal{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 40px;
  border: none;
  border-radius: 15px;
  text-decoration: none;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: 	#ffc439;
  border: 2px solid;
}

.buttonPayMethodPayPal:hover{
  background-color: 	#fabc2b;
  }

.buttonPayMethod:hover{
  background-color: #484848;
  }

.completedQuizText{
  font-size: 50px;
  font-weight: bold;
  color: green;
}

.completedQuizTextSmall{
  margin-top: 10px;
  font-size: 20px;
}

.alertDivPaying {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -55px;
  z-index: 1;
  width: 320px;
  height: 300px;
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
}

.alertDivPayingSeparatePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  z-index: 1;
  width: 320px;
  height: 300px;
  border-radius: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
}

.completedQuizTrialDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  display: flex;
  flex-direction: column;
}

.theBlock{
  height: 220px;
}

.theBlockPage{
  height: 220px;
  margin-top: 100px;
}

.completedQuizTextTrial{
  font-weight: bold;
  font-size: 19px;
  color:#1662b9;
}

.completedQuizTextTrialExtraPage{
  font-weight: bold;
  font-size: 17px;
  color:#1662b9;
}

.buttonBackToTestTrial{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  border-radius: 15px;
  text-decoration: none;
  background-color: #303030;
  color: white;
  border: 2px solid;
}

.textCompletedTrial{
 font-size: 15px;
 font-family: Arial, Helvetica, sans-serif;
}

.linkNoneStyle{
  text-decoration: none;
}

.buttonBackToTestTrial:hover{
  background-color: #484848;
  border: 2px solid;
  text-decoration: none;
  }

.buttonPayTestTrial{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  border-radius: 15px;
  text-decoration: none;
  color: white;
  background-color: green;
  border: 2px solid;
}

.buttonPayTestTrial:hover{
  background-color: darkgreen;
  border: 2px solid;
  text-decoration: none;
  }

  .trialPlus{
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .payingText{
    padding: 5px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 100px;
    border-radius: 10px;
  }

  .trialHSK1{
    font-size: 27px;
    font-weight: bold;
    color: #063264;
  }

  .price{
    display: flex;
    justify-content: center;
  }

  .paymentAmount{
    display: flex;
    flex-direction: row;
    align-items: end;
  }

  .svgEuro{
    margin-top: 5px;
  }

  .chatIncluded{
    font-family: Arial, Helvetica, sans-serif;
  }

  .timePayment{
    font-size: 12px;
    margin-bottom: 2.5px;
  }

  .priceNumber{
    font-size: 20px;
  }

  .congratulationsBlockEmpty{
    height: 300px;
    width: 320px;
  }

  .buttonAbsolute{
    position: absolute;
    margin-top: 380px;
    display: flex;
    flex-direction: column;
    width: 230px;
    margin-left: 9%;
  }

  @media screen and (max-width: 800px) {
    .buttonAbsolute {
        position: absolute;
        margin-left: 70%;
        margin-top: 15%;
    }
  }

  @media screen and (max-width: 755px) {
    .buttonAbsolute {
        position: absolute;
        margin-left: 97%;
        margin-top: 15%;
    }

  }

  @media screen and (max-width: 558px) {
    .buttonAbsolute {
      position: absolute;
      margin-top: 380px;
      display: flex;
      flex-direction: column;
      width: 230px;
      margin-left: 9%;
    }

  }

  .chooseFileTextLabel{
    background-color:   #585858;
    color: white;
    width: 100px;
    display: flex;
    justify-content: center;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 5px;
    cursor: pointer;
  }

  .chooseFileTextLabelNot{
    background-color:   #585858;
    color: white;
    width: 100px;
    display: flex;
    justify-content: center;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 5px;
    cursor: not-allowed;
  }

  .chooseFileText{
    display: none;
  }

  .changePhotoButton{
    width: 100px;
    display: flex;
    justify-content: center;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
  }

  .changePhotoButton:disabled{
    color: black;
    border-color: black;
    cursor: not-allowed;
  }

  .changePhotoButtonNot{
    width: 100px;
    display: flex;
    justify-content: center;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 5px;
    cursor: not-allowed;
    margin-top: 5px;
  }

  @media screen and (min-height: 552px) {
    .totalContainer{
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
    .footerDiv{
     margin-top: auto;
    }
    .main{
      height: 100vh;
    }
    .main2{
      min-height: 415px;
      height: 100vh;
      background-color: gray;
    }
  }

  .image-container{
    max-width: 100%;
    max-height: 400px; 
  }

  .deletePhotoButton{
    z-index: 3;
    position: absolute;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(137, 49, 64);
    border: none;
    color: white;
    width: 65px;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-left: 2%;
  }

  @media screen and (max-width: 800px) {
    .deletePhotoButton{
      position: absolute;
      margin-left: 45%;
      margin-top: -10%;
    }
  }

  @media screen and (max-width: 635px) {
    .deletePhotoButton{
      position: absolute;
      margin-left: 47%;
      margin-top: -10%;
    }
  }

  @media screen and (max-width: 590px) {
    .deletePhotoButton{
      position: absolute;
      margin-left: 55%;
      margin-top: -10%;
    }
  }


  .removeWordPhoto{
    margin-bottom: -3px;
  }

  .divForPostPhoto{
    height: 280px;
    width: 280px;
    object-fit: cover;
    border-radius: 50%;
    aspect-ratio: 1;
    z-index: 1;
  }

  .paidCourseText{
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: -50px;
    color: green;
  }

  .friendsPhoto{
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
    aspect-ratio: 1;
    z-index: 1;
    position: relative;
  }

  .containerPaying{
    display: grid;
    justify-content: center;
    align-content: center;
    height: 100vh;
  }

  .copyrightPage{
    width: 100%;
    min-height: 310px;
    height: 70vh;
    margin-top: -10px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .copyrightPageDiv{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .contactPageDivText1{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  .contactPageDivText{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 20px;
  }

  .paypal-container.disabled .buttonsPaypal {
    pointer-events: none;
  }

  .buttonSubmitInputQuiz{
    position: absolute;
    border-radius: 5px;
    left: calc(100% + 5px);
    top: 32%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 410px) {
    .buttonSubmitInputQuiz {
        width: 30px;
        height: 30px;
        left: calc(100% - 35px);
        top: 135%;
    }
  }

  .buttonSubmitInputQuizWrong{
    background-color: rgb(175,0,42);
  }

  .buttonSubmitInputQuizCorrect{
    background-color: rgb(200, 224, 93);
  }

  .formForQuizSubmit{
    position: relative;
  }

  .buttonBackToLoginPage{
    position: absolute;
    margin-top: 250px;
    cursor: pointer;
    background-color:#034694;
    color: white;
    border-radius: 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .resendCodeInfo{
    margin-top: 405px;
    position: absolute;
    color: gray;
    text-decoration: underline;
  }

  .foldersPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .FolderPageBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

  .h1Style{
    margin-top: -40px;
    margin-bottom: 20px;
    font-weight: bold;
    margin-bottom: 25px;
  }

  .folderBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 7px solid #252424;
    border-radius: 15%;
    height: 130px;
    width: 130px;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 30px;
    background-color: #F0F0F0;
}

  .folderText{
    font-size: 17px;
    font-weight: bold;
    border: none;
    color: #303030;
    text-decoration: none;
    white-space: nowrap;
  }

  .foldersFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    justify-content: flex-start;
    margin-left: 10.7%;
  }

.folderText:hover{
  color: black;
  }

.svgRounded{
  margin-top: 5px;
}

.editWordsListButton{
  position: absolute;
  margin-left: 90%;
  cursor: pointer;
}

.divForLearnWordSvgAbsolute2:hover .learnWordTextQuiz{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 150px;
  margin-left: -60px;
  margin-top: -58px;
}

.divIgnoreWord{
    cursor: pointer;
}

.divIgnoreWord:hover .learnWordTextQuiz{
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
  background-color: #404040;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 150px;
  margin-left: -20px;
  margin-top: -58px;
}

.learnAgainSvgAbsolute{
  cursor: pointer;
}

.absoluteDivFix{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 90%;
}

@media screen and (max-width: 1270px) {
  .absoluteDivFix {
    position: relative;
    margin-left: 10px;
    width: 55px;
  }
  .editWordsListButton {
    position: relative;
    margin-left: 10px;
    height: 30px;
    width: 55px;
  }
}

@media screen and (min-width: 1290px) {
  .absoluteDivFix {
    position: relative;
    margin-left: 10px;
    width: 55px;
  }
  .editWordsListButton {
    position: relative;
    margin-left: 10px;
    height: 30px;
    width: 55px;
  }
}

.addToIgnored{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7px;
  align-items: center;
  margin-top: 70px;
  margin-right: -85%;
  font-weight: normal;
  cursor: pointer;
}

.divMinus{
  display: flex;
  height: 5px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  opacity: 0.7;
}

@media screen and (max-width: 1210px){
  .addToIgnored {
    margin-right: -107%;
    margin-top: 70px;
  }
}

@media screen and (max-width: 800px){
  .addToIgnored{
    margin-right: -95%;
    margin-top: 70px;
  }
}

.informationAboutIgnoreWord{
  display: none; 
}

.addToIgnored:hover .informationAboutIgnoreWord{
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: -85px;
  background-color: #006400;
  border-radius: 7px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 13px;
  color: white;
  width: 100px;
  z-index: 5;
}

.pIgnoreTheWord{
  font-size: 20px;
  text-align: left;
  padding: 5px;
}

.askingBoxIgnoreTheWord{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 250px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  position: fixed;
  background-color: #fff;
  z-index: 1;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

.answerRemoveIgnoreTheWord{
  margin-top: 5px;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  justify-content: space-between;
  width: 180px;
}


.yesAnswerIgnoreTheWord{
  border: none;
  background-color:  #034694;
  opacity: 0.9;
  color: white;
  border-radius: 10px;
  width: 50px;
}

.yesAnswerIgnoreTheWord:hover {
  opacity: 1;
}

.noAnswerIgnoreTheWord{
  border: none;
  background-color: #B22222;
  opacity: 0.9;
  color: white;
  border-radius: 10px;
  width: 50px;
}

.noAnswerIgnoreTheWord:hover {
  opacity: 1;
}

